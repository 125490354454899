.title_wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    /* padding-bottom: 0.5rem; */
}

.title_left {
    width: 100%;
}

.phone_left {
    display: flex;
    flex-direction: row;
}

.phone_left p {
    padding-left: 5px;
    font-weight: 600;
    font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

.title_left h1 {
    font-weight: 700;
}

.org_wrap {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
}

.org_wrap img {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}

.org_wrap span {
    color: #c3fafafa;
    cursor: pointer;
}

.title_right span {
    font-size: 1.25rem;
    font-weight: 700;
}

.logo {
    font-size: 20px;
    padding: 0;
    margin-bottom: 15px;
}

.container {
    background-color: white;
    width: 1px;
    height: 19px;
    margin-bottom: 5px;
    opacity: 50%;
}

.links {
    color: white;

}

.circle {
    border-radius: 50%;
    background-image: var(--btn-gradient);
    padding: 5px;
    height: 30px;
    margin-top: 10px;
    margin-right: 5px;
}

@media screen and (max-width: 600px) {
    .title_left h1 {
        font-weight: 700;
        line-height: 30px;
        font-size: 1.5rem;
        margin-top: 0.61rem;
    }
}