.lineup_wrap {
  padding: 0.5rem 0;
}
.lineup {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.people {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
  margin-top: 1rem;
}
.people span {
  font-size: 1.25rem;
  font-weight: 700;
}
.lineup_img {
  background-position: top;
  background-size: cover;
  border-radius: 50%;
  height: 180px;
  max-height: 180px;
  max-width: 180px;
  min-height: 180px;
  min-width: 180px;
  width: 180px;
}
@media screen and (max-width: 500px) {
  .lineup_img {
    /* height: 140px;
    max-height: 140px;
    max-width: 140px;
    min-height: 140px;
    min-width: 140px;
    width: 140px; */
    /* display: contents; */
  }
  .lineup {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    transform: translateX(7.5%);
    /* justify-content: center; */
  }
}
/* @media screen and (max-width: 800px) {
  .lineup {
    justify-content: center;
  }
} */
