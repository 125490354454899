@keyframes shimmer {
  0% {
    background-position: -200px;
  }
  100% {
    background-position: calc(200px + 100%);
  }
}
@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

/* .skeleton {
  animation: shimmer var(--skeleton-animation-duration) infinite;
  background: linear-gradient(
    90deg,
    var(--skeleton-background-color) 0px,
    var(--skeleton-shimmer-color) 50%,
    var(--skeleton-background-color) 100%
  );
  background-size: 200px 100%;
  border-radius: var(--skeleton-border-radius);
} */

.skeleton {
  animation: pulse 2s infinite ease-in-out;
  background-color: var(--skeleton-background-color);
  border-radius: var(--skeleton-border-radius);
}
