.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.eventCardSkeleton {
  flex: 0 0 calc(33.33% - 2rem);
  display: flex;
  flex-direction: column;
  animation: pulse 2s infinite ease-in-out;
  z-index: 2;
}

.imageContainerSkeleton {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 9 / 16 * 100% */
  overflow: hidden;
  background-color: #ddd;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.eventImageSkeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.eventCard a {
  text-decoration: none;
  color: black;
}
.nameSkeleton {
  margin-top: 0.5rem;
  width: 8rem;
  height: 2rem;
  border-radius: 5px;
  background-color: #ddd;
}
.event_infoSkeleton {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.event_infoSkeleton span {
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 5px;
  height: 2rem;
  margin-top: 0.5rem;
  background-color: #ddd;
  color: #ddd;
}
@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

@media screen and (max-width: 1120px) {
  .eventCardSkeleton {
    flex: 0 0 calc(50% - 2rem);
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 680px) {
  .eventCardSkeleton {
    flex: 0 0 calc(100%);
    display: flex;
    flex-direction: column;
  }
}
