.skeleton_text_info {
  display: flex;
  flex-direction: column;
  border-bottom: 0.2px solid #7b57636c;
  border-top: 0.2px solid #7b57636c;
  padding-bottom: 1rem;
}

.wrap_skeleton1 {
  height: 1.5rem;
  width: 50%;
  margin-bottom: 0.5rem;
}

.wrap_skeleton {
  height: 1.5rem;
  width: 30%;
  margin-bottom: 0.5rem;
}
