.homepage {
    width: unset;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    margin-top: 5%;
}

.event {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 10px;
    margin-bottom: 1rem;
}

.header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
}

.header span {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    font-size: 1rem;
}

@media screen and (max-width: 1000px) {
    .header h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .homepage {
        margin: 20px 5%;
        width: 90vw;
    }

    .header h1 {
        font-size: 1.8rem;
    }

    .header {
        gap: 1rem;
        margin: 0;
    }
}

@media screen and (max-width: 400px) {
    .header h1 {
        font-size: 1.4rem;
    }
}