/* dialog.css */

/* Backdrop styles */
.dialogMain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.dialogBackdrop {
    position: fixed;
    inset: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
    /* Adjust the blur amount as needed */
}

.year input::-webkit-outer-spin-button,
.year input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.year input[type=number] {
    -moz-appearance: textfield;
}

/* Dialog panel styles */
.dialogPanel {
    position: absolute;
    top: 25%;
    transform: translate(-50%, 0%);
    background-color: #1b212d;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    width: 90%;
}

.dialogTitle {
    font-size: 20px;
    font-weight: bold;
}

.dialogDescription {
    margin-bottom: 15px;
    line-height: 20px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
}

.dialogContent {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dialogButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.dialogButtons button {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 1rem;
    border-radius: var(--ticket-border-radius);
    border: none;
    background-image: var(--btn-gradient);
    font-size: 1.25rem;
    font-weight: 700;
    color: white;
}

.dialogContent input {
    background-color: black;
    border-radius: 15px;
    color: white;
    border: none;
    padding: 0 10px;
    /* box-shadow: black; */
}

.year {
    width: 30%;

}

.month {
    width: 25%;
}

.day {
    width: 25%;
}