.title_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* padding-bottom: 0.5rem; */
}

.title_left {
  width: 70%;
}

.title_left h1 {
  font-weight: 700;
}

.org_wrap {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
}

.org_wrap img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.org_wrap span {
  color: #c3fafafa;
  cursor: pointer;
  width: max-content;
}

.title_right span {
  font-size: 1.25rem;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .title_left h1 {
    font-weight: 700;
    line-height: 30px;
    font-size: 1.5rem;
    margin-top: 0.61rem;
  }
}