.description_wrap {
  padding: 1rem 0;
}

.description_wrap p {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  padding: 0.5rem 0;
}
.description_wrap * {
  line-height: normal !important;
}

@media screen and (max-width:600px) {
  .description_wrap {
    padding: 1rem 25px;
  }
}