.text_info {
  display: flex;
  flex-direction: column;
  border-bottom: 0.2px solid #7b57636c;
  border-top: 0.2px solid #7b57636c;
  padding: 0.5rem 0;
}

.href {
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.text_info div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 0.9rem;
}

.logo {
  font-size: 1.5rem;
}

.dateRange {
  background: var(--text-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 0.9rem;
}

.dateText {
  font-size: 0.9rem;
}

.location_info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 0.9rem;
  cursor: pointer;
}