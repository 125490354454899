.wrapper {
  display: flex;
  /* align-items: center; */
  text-align: left !important;
  flex-direction: column;
  animation: pulse 2s infinite ease-in-out;
  margin-top: 1rem;
}
.wrap_title {
  display: flex;
  gap: 2rem;
}
.titleSkeleton {
  width: 24rem;
  height: 2rem;
  background-color: #ddd;
  margin-bottom: 1rem;
  border-radius: 5px;
}
.titleSkeleton_1 {
  color: #ddd;
  background-color: #ddd;
  height: 2rem;
  border-radius: 5px;
  width: 0.2rem;
}
.titleSkeleton_2 {
  color: #ddd;
  background-color: #ddd;
  height: 2rem;
  width: 7rem;
  border-radius: 5px;
}
.eventImageSkeleton {
  max-width: 50% !important;
  border-radius: 15px;
  object-fit: cover;
  width: auto !important;
  min-width: 50% !important;
  max-height: 335px !important;
  min-height: 335px !important;
  height: auto !important;
  background-color: #ddd;
}
.descSkeleton {
  line-height: 25px;
  margin-top: 1rem;
  background-color: #ddd;
  width: 70%;
  width: 2rem;
  height: 1rem;
}
.eventInfoSkeleton {
  padding-left: 20px; /* adjust as necessary */
  width: 50% !important;
  width: 4rem;
  height: 2rem;
}

.event_infoSkeleton {
  display: flex;
  flex-direction: column;
}
.event_titleSkeleton {
  text-transform: uppercase;
}
@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}
@media screen and (max-width: 1000px) {
  .event_titleSkeleton {
    font-size: 1.5rem;
  }
  /* .eventImage {
    width: 300px;
    height: 167px;
    border-radius: 15px;
    object-fit: cover;
  } */
}
@media screen and (max-width: 850px) {
  .wrapperSkeleton {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .eventImageSkeleton {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .eventInfoSkeleton {
    text-align: center;
    width: 100% !important;
    bottom: 0;
  }

  .event_titleSkeleton {
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
  }

  .eventImageSkeleton {
    max-height: 256px !important;
    min-height: 256px !important;
  }
  .descSkeleton {
    display: none;
  }
}
