.wrapper {
  display: flex;
  flex-wrap: wrap;
  /* width: var(--main-width-event-details); */
  gap: 0.5rem;
  padding-bottom: 0.5rem;
}

.wrapper div {
  background-color: rgba(255, 255, 255, 31%);
  color: white;
  font-weight: 600;
  padding: 0 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  line-height: 35px;
}