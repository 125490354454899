.dialog {
  /* background: rgba(0, 0, 0, 0.4); */
  background-color: white;
  inset: 0;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
}

.searchBar {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 2rem;
  z-index: 2;
}

.input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: black !important;
}

.date {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.event {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.close {
  position: absolute;
  font-size: 2rem;
  cursor: pointer;
  /* background-image: var(--btn-gradient); */
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  z-index: 4;
  border-radius: 50%;
  padding: 3px 10px;
  font-size: 1.4rem;
  top: 2rem;
  font-weight: 800;
  line-height: normal;
}
