.title_wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 0.5rem; */
}

.title_left {
    width: 70%;
}

.title_left h1 {
    font-weight: 700;
}

.org_wrap {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
}

.org_wrap img {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}

.org_wrap span {
    color: #c3fafafa;
    cursor: pointer;
}

.title_right span {
    font-size: 1.25rem;
    font-weight: 700;
}

.logos {
    background-color: rgba(255, 255, 255, 34%);
    /* opacity: 60%; */
    width: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 100px;
    height: 30px;
    padding-top: 5px;
    gap: 4px;
    padding-left: 5px;
    padding-right: 5px;
}

.container {
    background-color: white;
    width: 1px;
    height: 19px;
    margin-bottom: 5px;
    opacity: 50%;
}

.links {
    color: white;

}

@media screen and (max-width: 600px) {
    .title_left h1 {
        font-weight: 700;
        line-height: 30px;
        font-size: 1.5rem;
        margin-top: 0.61rem;
    }
}