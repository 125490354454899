/* VerifyEmail.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: whitee;
  display: none;
  z-index: 10000000;
}
.row {
  display: flex;
  align-items: center;
}
.modal {
  position: fixed;
  bottom: 0%;
  background: white;
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  opacity: 1;
  background-color: black;
  transition: opacity 0.5s ease-out;
  border-top: 1px solid white;
}

.fadeOut {
  opacity: 0 !important;
}

.title {
  font-size: 1.2em; /* smaller title on mobile */
  font-weight: 600;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  font-size: 1.2rem;
  color: white;
}

.btn {
  width: 2rem;
  height: 2rem;
  background-image: var(--btn-gradient);
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.body {
  font-size: 0.9em; /* smaller text on mobile */
  margin-bottom: 2em;
  display: flex;
  gap: 1rem;
}
.body div:first-child {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.status {
  font-size: 8rem;
  border-radius: 50%;
}

.success {
  fill: #04a24d;
}
.error {
  fill: #e21c3d;
}
.message {
  line-height: 30px;
}
.a {
  background-color: blue;
  color: white;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%; /* full-width button on mobile */
}

.btn_wrap {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  width: 100%;
}
.btn_wrap a {
  background-image: var(--btn-gradient);
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  color: white !important;

  cursor: pointer;
}
@media screen and (max-width: 820px) {
  .overlay {
    display: block;
  }
}
