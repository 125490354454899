.title_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-top: 1.5rem;
}

.title_left {
  width: 70%;
}

.title_skeleton {
  height: 1.5rem;
  margin-bottom: 20px;
}

.org_wrap_skeleton {
  height: 1rem;
  width: 40%;
}

.title_right {
  width: 10%;
}

.date_skeleton {
  height: 1.25rem;
  width: 80%;
}
