.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: 0.5rem;
  position: relative;
}

.bg {
  position: fixed;
  background-image: url("/assets/bgnew.jpg");
  height: 100%;
  top: 0;
  z-index: -1;
  /* filter: blur(30px); */
  width: 100%;
  background-size: cover;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 80vw;
  margin: 0 10%;
}

.row {
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
}

.dateSkeleton {
  width: 18rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-top: 1rem;
  animation: pulse 2s infinite ease-in-out;
  background-color: #ddd;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.8;
  }
}

@media screen and (max-width: 600px) {
  .column {
    width: 90vw;
    margin: 0 5%;
  }
}